import api from '../utils/api'
import omit from "lodash/omit";
import get from "lodash/get";
import { mergeAttachedRequestForms } from './request-form';
import BaseService from './baseService';
import { mapGalleryPhotos } from '../mappers/GalleryPhotosMapper';

export const BLOCK_TYPE_SERVICES = 'BLOCK_TYPE_SERVICES'
export const BLOCK_TYPE_DOCTORS = 'BLOCK_TYPE_DOCTORS'
export const BLOCK_TYPE_ARTICLES = 'BLOCK_TYPE_ARTICLES'
export const BLOCK_TYPE_BANNER = 'BLOCK_TYPE_BANNER'
export const BLOCK_TYPE_ABOUT = 'BLOCK_TYPE_ABOUT'
export const BLOCK_TYPE_LOCATION = 'BLOCK_TYPE_LOCATION'
export const BLOCK_TYPE_GALLERY = 'BLOCK_TYPE_GALLERY'
export const BLOCK_TYPE_HERO = 'BLOCK_TYPE_HERO'
export const BLOCK_TYPE_PRACTICES = 'BLOCK_TYPE_PRACTICES'
export const BLOCK_TYPE_CONTACTS = 'BLOCK_TYPE_CONTACTS'
export const BLOCK_TYPE_CONTENT = 'BLOCK_TYPE_CONTENT'

export const PRACTICES_VIEW_BOX = 'box'
export const PRACTICES_VIEW_CARD = 'card'
export const PRACTICES_VIEWS = {
  PRACTICES_VIEW_BOX,
  PRACTICES_VIEW_CARD
}

export const BLOCK_NAMES = {
  BLOCK_TYPE_SERVICES,
  BLOCK_TYPE_DOCTORS,
  BLOCK_TYPE_ARTICLES,
  BLOCK_TYPE_BANNER,
  BLOCK_TYPE_ABOUT,
  BLOCK_TYPE_LOCATION,
  BLOCK_TYPE_GALLERY,
  BLOCK_TYPE_HERO,
  BLOCK_TYPE_PRACTICES,
  BLOCK_TYPE_CONTACTS,
  BLOCK_TYPE_CONTENT,
}

export const BLOCK_MARGIN_MD = 'md'
export const BLOCK_MARGIN_SM = 'sm'
export const BLOCK_MARGINS = {
  BLOCK_MARGIN_MD,
  BLOCK_MARGIN_SM
}

class ClinicsService extends BaseService {
  get url () {
    return 'clinics'
  }

  async get (uri) {
    let clinic = await api.get(`clinics/${uri}`)
    return {
      ...clinic,
      generalManagerTitle: clinic.generalManagerTitle || {},
      dutyDoctorTitle: clinic.dutyDoctorTitle || {},
      requestForms: mergeAttachedRequestForms(clinic.requestForms)
    }
  }

  getAll (params) {
    return api.get('clinics', { params: params })
  }

  remove (clinicId) {
    return api.delete(`clinics/${clinicId}`)
  }

  order (organizationIds = []) {
    return api.post('clinics/order', {
      organizationIds
    })
  }

  toDto (entity) {
    let n = omit(entity, [
      'socialImage',
      'attachedDoctors',
      'attachedServices',
      'mainSquarePhoto',
      'mainWidePhoto',
      'cardImage',
      'generalManagers'
    ])
    n.cardImageId = get(entity, 'cardImage.id', null)
    n.socialImageId = get(entity, 'socialImage.id', null)
    n.attachedDoctorIds = entity.attachedDoctors.map(({ id }) => id)
    n.attachedServiceIds = entity.attachedServices.map(({ id }) => id)
    n.requestForms = entity.requestForms.map((form) => ({
      code: form.code,
      title: form.title,
      channelId: form.channelId,
      comment: form.comment
    }))
    n.journalPosts = entity.journalPosts.map((journalPost) => {
      return {
        postId: journalPost.post.uuid,
        order: journalPost.order
      }
    })
    n.gallery = entity.gallery.map(({ id, title, photo, type, embeddedVideo }) => ({
      id,
      type,
      title,
      embeddedVideo,
      photoId: photo?.id
    }))
    n.mainSquarePhotoId = entity.mainSquarePhoto?.id
    n.mainWidePhotoId = entity.mainWidePhoto?.id
    n.generalManagers = (entity.generalManagers || []).map((generalManager) => {
      generalManager.contacts = generalManager.contacts.filter((contact) => {
        return contact.value && contact.value.trim() !== ""
      })
      return generalManager
    })
    n.practiceViewType = n.practiceViewType || PRACTICES_VIEW_BOX
    return n
  }
}

export default new ClinicsService()
