<template>
  <div class="meli">
    <div class="meli__title">
      {{ title }}
    </div>
    <div v-if="item.type === TYPE_LIST" class="meli__items caption">
      <ul v-if="subitems.length" class="meli__items-list">
        <li v-for="subitem in subitems" :key="subitem.$key">
          {{ subitem.title }}
        </li>
      </ul>
      <span v-else>Список пуст</span>
    </div>
    <div class="caption color-gray">
      <div v-if="item.type === TYPE_URL">
        Ссылка: {{ item.url }}
      </div>
      <div v-if="item.type === TYPE_PAGE">
        Страница: {{ item.pageId }}
      </div>
      <div v-if="item.trackingName">
        Метка: {{ item.trackingName }}
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_LIST, TYPE_URL, TYPE_PAGE } from '@/models/MenuItem'
import { v4 as uuid } from 'uuid'

function getTitle (item) {
  const { label } = item
  return `${label.ru} (${label.en})`
}

export default {
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      TYPE_LIST,
      TYPE_URL,
      TYPE_PAGE
    }
  },
  computed: {
    title () {
      return getTitle(this.item)
    },
    subitems () {
      return (this.item.items || []).map((item) => ({
        ...item,
        title: getTitle(item),
        $key: uuid()
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.meli {

}

.meli__items {
}

.meli__items-list {
  padding-left: 1em;
  margin-top: 4px;
  list-style-type: square;
  & > * {
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
